import React, { useEffect, useState, useRef, createRef } from 'react';
import Link from '../UI/Link/Link';
import AssetZenImage from '../UI/AssetZenImage/AssetZenImage';
import { StickyContainer, Sticky } from 'react-sticky';
import styles from './ScrollSlider.module.scss';

const ScrollSlider = ({ items }) => {
    const imgRefs = useRef([...Array(items.length)].map(() => createRef()));
    const [currentIndex, setCurrentIndex] = useState(0);

    function handleIntersect(entries, observer) {
        entries.forEach(entry => {
            const index = imgRefs.current.findIndex(item => item.current === entry.target);

            if (entry.isIntersecting) {
                setCurrentIndex(index);
            }
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: [0.5]
        });

        imgRefs.current.forEach(({ current }) => {
            observer.observe(current);
        });

        return () => {
            observer.disconnect();
        }
    }, []);

    return (
        <StickyContainer className={styles.scrollSlider}>
            <Sticky disableCompensation>
                {({ style }) => {
                    return (
                        <div className={styles.inner} style={style}>
                            <div className={styles.progress}>
                                <span className={styles.progressNum}>{('0' + (currentIndex + 1)).slice(-2)}.</span>
                                <span className={styles.progressOf}>
                                    of <span className={styles.progressBar} style={{ height: 100 * ((currentIndex + 1) / items.length) }} />
                                </span>
                                <span className={styles.progressNum}>{('0' + items.length).slice(-2)}.</span>
                            </div>

                            <ul className={styles.list}>
                                {items.map((item, index) => {
                                    const isActive = currentIndex === index;

                                    return (
                                        <li className={isActive ? styles.listItemActive : styles.listItem} key={index}>
                                            <strong className={styles.listItemMeta}>{item.name}</strong>
                                            <h3 className={styles.listItemTitle}>
                                                <Link className={styles.listItemLink} to={item.slug}>
                                                    {item.title}
                                                </Link>
                                            </h3>
                                            <p className={styles.listItemDesc}>{item.meta && item.meta.description}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                }}

            </Sticky>
            <div className={styles.images}>
                {items.map((item, index) => (
                    <div ref={imgRefs.current[index]} key={index}>
                        <Link className={styles.imageLink} to={item.slug} aria-label={item.title}>
                            <AssetZenImage className={styles.image} id={item.image.id} width="670" height="945"/>
                        </Link>
                    </div>
                ))}
            </div>
        </StickyContainer>
    )
}

ScrollSlider.propTypes = {

};

export default ScrollSlider;
