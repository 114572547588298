import React from 'react';
import Section from '../Section/Section';
import VideoBg from '../VideoBg/VideoBg';
import ButtonLink from '../UI/ButtonLink/ButtonLink';
import { HTMLContent } from '../Content';
import preventOrphan from '../../utils/preventOrphan';
import Fade from 'react-reveal/Fade';
import placeholder from '../../img/video-placeholder.jpg';
import styles from './Hero.module.scss';

const Hero = ({ title, introduction, link, video, ...other }) => {
    return (
        <Section className={styles.hero} {...other} isUnstyled={true} style={{ minHeight: typeof window !== 'undefined' ? window.innerHeight : null }}>
            <div className={styles.inner}>
                <Fade bottom>
                    <h1 className={styles.title}>
                        <HTMLContent content={title} />
                    </h1>

                    <div className={styles.body}>
                        <div className={styles.intro}>
                            {introduction.split('|').map((para, index) =>
                                <p key={index} dangerouslySetInnerHTML={{ __html: preventOrphan(para) }} />
                            )}
                        </div>
                        
                        <ButtonLink to={link.url} isReverse={true}>{link.text}</ButtonLink>
                    </div>
                </Fade>
            </div>
            

            {video && video.src &&
                <VideoBg className={styles.video} src={video.src} timestamps={video.timestamps} placeholder={placeholder} />
            }
        </Section>
    )
};

export default Hero;
