import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Section from '../Section/Section';
import ScrollSlider from '../ScrollSlider/ScrollSlider';
import SnapSlider from '../SnapSlider/SnapSlider';
import FeatureCard from '../UI/FeatureCard/FeatureCard';
import Fade from 'react-reveal/Fade';
import styles from './Today.module.scss';

const Today = ({ title, items, ...other }) => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1023px)'
  });

  return (
    <Section {...other} className={styles.today}>
      <Fade bottom>
        <h2 className={styles.title}>{title}</h2>
      </Fade>
      
      <div className={styles.body}>
        {!isTabletOrMobile ?
          <ScrollSlider items={items}/>
          :
          <SnapSlider>
            {items.map((item, index) => (
              <FeatureCard {...item} key={index}/>
            ))}
          </SnapSlider>
        }
      </div>
      
    </Section>
  )
}

Today.propTypes = {
  
};

export default Today;
