import React from 'react';
import { graphql } from 'gatsby';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout'
import Hero from '../components/Hero/Hero';
import Today from '../components/Today/Today';
import SectionRenderer from '../components/SectionRenderer/SectionRenderer';
import parseMarkdown from '../utils/parseMarkdown';

export const HomePageTemplate = ({
    hero,
    today,
    sections
}) => {
    today.items.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
    });

    return (
        <>
            <Hero {...hero} title={hero.title} theme={themes.DARK} />
            <Today {...today} theme={themes.LIGHT} />
            <SectionRenderer data={parseMarkdown(sections)} />
        </>
    )
}

const HomePage = ({ data: { page } }) => {
    const { today, ...other } = parseMarkdown(page);
    let todayItems = [];

    if (today.work) {
        todayItems = todayItems.concat(parseMarkdown(today.work));
    }

    if (today.pages) {
        todayItems = todayItems.concat(parseMarkdown(today.pages));
    }

    return (
        <Layout meta={page.frontmatter.meta || false} theme={themes.DARK}>
            <HomePageTemplate {...other} today={{ ...page.frontmatter.today, items: todayItems }} />
        </Layout>
    )
}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePageTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Sections
      ...Pages
      frontmatter {
        hero {
          title
          introduction
          video {
            src
            timestamps
          }
          link {
            text
            url
          }
        }
        today {
          title
          pages {
            fields {
              slug
            }
            frontmatter {
              name
              title
              date
              image {
                id
              }
              meta {
                description
              }
            }
          }
          work {
            fields {
              slug
            }
            frontmatter {
              name
              title
              date
              image {
                id
              }
              meta {
                description
              }
            }
          }
        }
      }
    }
  }
`
