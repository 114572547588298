import React from 'react';
import Link from '../Link/Link';
import AssetZenImage from '../AssetZenImage/AssetZenImage';
import styles from './FeatureCard.module.scss';

const FeatureCard = ({ slug, name, title, image }) => {
    return (
        <article className={styles.card}>
            <Link className={styles.inner} to={slug} draggable="false">
                <div className={styles.body}>
                    <strong className={styles.meta}>{name}</strong>
                    <h3 className={styles.title}>{title}</h3>
                </div>
                <AssetZenImage className={styles.image} id={image.id} draggable={false} alt="" style={{ position: 'absolute'}}/>
            </Link>
        </article>
    )
}

FeatureCard.propTypes = {

};

export default FeatureCard;
